//breadcrumb//
.breadcrumb-menu{
    padding: 16px 0;
    border-radius: 0;
    margin: 0 0 48px 0;
    background-color: $main-clr;
    @include media-breakpoint-down(md){
        padding: 12px 0;
        margin: 0 0 40px 0;
    }
    @include media-breakpoint-down(sm){
        padding: 8px 0;
        margin: 0 0 32px 0;
    }
    .breadcrumb-wrapper{
        .breadcrumb{
            background-color: transparent;
            padding: 0;
            margin: 0;
            li{
                font-size: 16px;
                @include media-breakpoint-down(sm){
                    font-size: 14px;
                }
                a{
                    color: $white;
                    font-size: 16px;
                    @include media-breakpoint-down(sm){
                        font-size: 14px;
                    }
                    i{
                        margin-right: 4px;
                    }
                }
                &.active{
                    color: $white;
                }
                + li{
                    &::before{
                        color: $white !important;
                    }
                }
            }
        }
    }
}
//breadcrumb end//