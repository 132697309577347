//my form//
.my-form {
  box-shadow: $my-shadow;
  padding: 32px;
  margin: 16px 0;
  border-radius: 4px;
  @include media-breakpoint-down(lg){
    padding: 32px 20px;
  }
  .form-group{
    margin-bottom: 0;
    h5 {
      font-size: 24px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 2px;
      color: $dark;
      margin-bottom: 24px;
      @include media-breakpoint-down(md) {
        font-size: 20px;
        margin-bottom: 20px;
      }
    }
    label{
      font-size: 16px;
      font-weight: 600;
      &.required{
        &::after{
          content: "*";
          position: relative;
          margin-left: 4px;
          color: red;
        }
      }
    }
    .form-control{
      margin-bottom: 20px;
      height: 56px;
      box-shadow: none;
      outline: none;
      border-radius: 0 !important;
      border:none;
      background-color: #edf0f4 !important;
      font-size: 16px;
      color: #6c757d;
      @include media-breakpoint-down(md){
        margin-bottom: 16px;
      }
      @include media-breakpoint-down(sm){
        margin-bottom: 12px;
      }
      &:focus{
        outline: none;
        border-color: $main-clr;
      }
    }
    textarea{
      &.form-control{
        resize: none;
        height:auto;
      }
    }
  }
  .form-check {
    margin-bottom: 20px;
    .form-check-label {
      a {
        font-size: 16px;
        font-weight: 500;
        @include media-breakpoint-down(md) {
          font-size: 14px;
        }
      }
    }
  }
  .form-link {
    margin-top: 20px;
    a {
      color: $dark;
      @include transition;
      &:hover{
        color: $main-clr;
      }
    }
  }
}
//my form end//