//footer//
footer {
  position: relative;
  background-color: #191919;
  padding-top: 56px;
  z-index: 999;
  @include media-breakpoint-down(md) {
    padding-top: 40px;
  }
  @include media-breakpoint-down(sm) {
    padding-top: 24px;
  }
  a {
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    color: $light !important;
    @include transition;
    @include media-breakpoint-down(lg){
      font-size: 14px;
    }
    @include media-breakpoint-down(md){
      font-size: 16px;
    }
    &:hover {
      text-decoration: none;
      margin-left: 8px;
    }
  }
  h5 {
    font-weight: 600;
    color: $light;
    @include media-breakpoint-down(lg) {
      font-size: 20px;
    }
    @include media-breakpoint-down(md) {
      padding-top: 20px;
    }
    @include media-breakpoint-down(sm) {
      margin-top: 12px;
      border-top: 1px solid #ffffff14;
    }
  }
  .contacts {
    @include media-breakpoint-down(sm) {
      margin-top: 0;
      border: none;
    }
  }
  ul {
    margin-top: 24px;
    padding: 0;
    li {
      list-style-type: none;
      margin-bottom: 12px;
      &:hover{
        a{
          color: $main-clr !important;
        }
        i{
          color: $main-clr !important;
        }
      }
      i {
        font-size: 16px;
        color: $light;
        margin-right: 4px;
        @include transition;
      }
      a {
        color: $light;
      }
    }
  }
  .fa-chevron-right {
    font-size: 12px;
    @include media-breakpoint-down(md) {
      font-size: 10px;
    }
    @include media-breakpoint-down(sm) {
      font-size: 12px;
    }
  }
  .footer-bottom {
    margin-top: 32px;
    padding: 20px 0;
    border-top: 1px solid #ffffff14;
    @include media-breakpoint-down(md){
      margin-top: 20px;
    }
    p {
      font-size: 16px;
      font-weight: 500;
      color: $light;
      margin-bottom: 0;
      @include media-breakpoint-down(lg){
        font-size: 14px;
      }
      a {
        color: $light !important;
        font-size: 16px;
        font-weight: 500;
        margin: 0;
        padding: 0;
        @include transition;
        @include media-breakpoint-down(lg){
          font-size: 14px;
        }
        &:active, &:hover{
          color: $main-clr !important;
        }
      }
    }
    .pay-ul{
      display: flex;
      justify-content: left;
      align-items: center;
      padding: 2px 0;
      margin: 0;
      li{
        padding: 2px;
        margin: 0 4px 0 0;
        border-radius: 3px;
        background-color: #ededed;
        img{
          height: 20px;
          @include media-breakpoint-down(md){
            height: 16px;
          }
        }
      }
    }
  }
  .social-icon-area {
    text-align: right;
    @include media-breakpoint-down(sm) {
      padding-top: 12px;
      text-align: left;
    }
    .social-icon {
      a {
        padding-left: 8px;
        margin: 0;
        @include media-breakpoint-down(md) {
          padding-left: 4px;
        }
        i {
          width: 36px;
          padding: 12px 0 !important;
          text-align: center;
          color: $light;
          background-color: #ffffff14;
          font-size: 16px;
          border-radius: 4px;
          @include transition;
          @include media-breakpoint-down(md) {
            font-size: 14px;
            width: 32px;
            padding: 8px 0 !important;
          }
        }
        &:active, &:hover {
          i {
            color: $main-clr;
          }
        }
      }
    }
  }
}
//footer end//