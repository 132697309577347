.pro-card{
  width: 100%;
  margin-top: 40px;
  @include media-breakpoint-down(lg){
    margin-top: 32px;
  }
  @include media-breakpoint-down(md){
    margin-top: 24px;
  }
  @include media-breakpoint-down(sm){
    margin-top: 16px;
  }
  .pro-img{
    margin-bottom: 20px;
    @include media-breakpoint-down(md){
      margin-bottom: 16px;
    }
    img{
      width: 100%;
      border-radius: 4px;
      border: 1px solid #00000014;
    }
  }
  .pro-text{
    a{
      h5{
        font-size: 20px;
        font-weight: 600;
        color: $dark;
        margin-bottom: 1px;
        @include transition;
      }
    }
    p{
      font-size: 16px;
      line-height: 24px;
      @include media-breakpoint-down(md){
        font-size: 14px;
      }
    }
  }
  &:hover{
    .pro-text{
      a{
        h5{
          color: $main-clr;
        }
      }
    }
  }
}