.blog-card {
  text-align: left;
  margin-bottom: 40px;
  @include transition;
  @include media-breakpoint-down(lg){
    margin-bottom: 32px;
  }
  @include media-breakpoint-down(sm){
    margin-bottom: 24px;
  }
  .blog-img {
    margin-bottom: 20px;
    @include media-breakpoint-down(md){
      margin-bottom: 16px;
    }
    img {
      width: 100%;
      border-radius: 4px;
    }
  }
  .blog-text {
    padding: 0;
    h5 {
      font-weight: 600;
      color: $dark;
      margin-bottom: 0;
      @include transition;
      @include media-breakpoint-down(lg) {
        font-size: 20px;
      }
    }
    .blog-p{
      margin: 12px 0;
    }
    .read-btn {
      font-size: 16px;
      font-weight: 600;
      color: $green;
      @include transition;
      @include media-breakpoint-down(md) {
        font-size: 14px;
      }
      i {
        font-size: 16px;
        color: $green;
        @include transition;
        @include media-breakpoint-down(md) {
          font-size: 14px;
        }
      }
      &:hover {
        margin-left: 5px;
      }
    }
  }
  &:hover{
    .blog-text{
      h4{
        color: $dark;
      }
    }
  }
}
.blog-date {
  padding: 0;
  margin: 0;
  @include media-breakpoint-down(md) {
    padding: 4px 0 0 0;
  }
  li {
    display: inline-block;
    margin: 0 16px 12px 0;
    @include media-breakpoint-down(md) {
      margin: 0 8px 8px 0;
    }
    a {
      font-size: 14px;
      font-weight: 500;
      color: $dark;
      @include transition;
      i {
        color: $main-clr;
        @include transition;
        margin-bottom: 4px;
      }
    }
  }
}