.pro-card-2{
  padding: 0;
  border-radius: 4px;
  border: 1px solid #ebebeb;
  background-color: $white;
  margin-top: 20px;
  @include media-breakpoint-down(lg){
    margin-top: 12px;
  }
  @include media-breakpoint-down(sm){
    margin-top: 8px;
    border: none;
  }
  .pro-img{
    padding: 0;
    @include media-breakpoint-down(sm){
      margin-bottom: 8px;
    }
    img{
      border-radius: 0 !important;
      border: none !important;
    }
  }
  .pro-text{
    text-align: left;
    padding: 0 8px 8px 8px;
    @include media-breakpoint-down(sm){
      padding: 0 4px 8px 4px;
    }
    h5{
      font-size: 16px !important;
      @include media-breakpoint-down(sm){
        font-size: 15px !important;
      }
    }
    .description{
      margin-top: 8px;
      font-size: 13px;
      line-height: 20px;
      @include media-breakpoint-down(sm){
        font-size: 11.4px;
        line-height: 16px;
      }
    }
  }
  .pro-ul{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    li{
      margin: 0 4px;
      @include transition;
      @include media-breakpoint-down(sm){
        margin: 0 1px;
      }
      h6{
        font-size: 16px;
        font-weight: 600;
        color: $green;
        margin: 0;
        @include media-breakpoint-down(lg){
          font-size: 15px;
        }
        @include media-breakpoint-down(sm){
          margin: 0;
          font-size: 14px;
        }
      }
      a{
        font-size: 14px;
        font-weight: 500;
        color: $black;
        width: 100%;
        padding: 4px 8px;
        border-radius: 0;
        @include transition;
        @include media-breakpoint-down(lg){
          font-size: 12.4px;
          padding: 4px 5px;
        }
        @include media-breakpoint-down(sm){
          font-size: 12px;
          padding: 2px 4px;
        }
        &:hover{
          color: $white;
        }
      }
      .customise-btn{
        outline: none;
        box-shadow: none;
        background-color: $main-clr;
        @include transition;
        &:hover{
          background-color: $s-main-clr;
        }
      }
      .basket-btn{
        outline: none;
        box-shadow: none;
        background-color: $main-clr;
        @include transition;
        &:hover{
          background-color: $s-main-clr;
        }
      }
    }
  }
  &:hover{
    .pro-text{
      a{
        h5{
          color: $dark !important;
        }
      }
    }
  }
}
.prod.list-view{
  .pro-card-2{
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 12px;
    @include media-breakpoint-down(lg){
      padding: 8px;
    }
    @include media-breakpoint-down(sm){
      padding: 6px 4px;
      margin-top: 8px;
      border-radius: 0 !important;
      border-bottom: 1px solid #ebebeb !important;
    }
    .pro-img{
      padding: 4px;
      margin: 0;
      width: 220px;
      @include media-breakpoint-down(lg){
        width: 180px;
      }
      @include media-breakpoint-down(sm){
        width: 120px;
        margin-bottom: 12px;
      }
      img{
        border-radius: 4px;
        width: 100%;
      }
    }
    .pro-text {
      width: 100%;
      text-align: left;
      padding: 0 0 0 12px;
      @include media-breakpoint-down(lg){
        padding: 0 0 0 8px;
      }
      @include media-breakpoint-down(sm){
        padding: 0;
      }
      h5{
        font-size: 16px !important;
        @include media-breakpoint-down(sm){
          font-size: 15px !important;
        }
      }
    }
    .pro-ul{
      padding: 0;
      justify-content: space-between;
      align-items: center;
      li{
        margin: 0 4px;
        @include transition;
        h6{
          margin: 8px 0;
          @include media-breakpoint-down(sm){
            font-size: 15px;
          }
        }
        a{
          padding: 4px 8px;
          @include media-breakpoint-down(sm){
            padding: 2px 8px;
          }
        }
        .customise-btn{
          @include transition;
        }
        .basket-btn{
          @include transition;
        }
      }
    }
  }
}