//slide//
#my-slider {
  width: 100%;
  transition: all 0.9s;
  .carousel-item{
    width: 100%;
    img {
      width: 100%;
      height: auto;
      @include media-breakpoint-down(sm){
        height: 300px;
        object-fit: cover;
        object-position: center;
      }
    }
  }
  .carousel-control-next{
    position: absolute;
    top: 40%;
    right: 30px;
    width: 30px;
    height: 30px;
    opacity: 1;
    @include media-breakpoint-down(lg){
      right: 15px;
    }
    @include media-breakpoint-down(sm){
      top: 42%;
      right: 0;
    }
  }
  .carousel-control-prev{
    position: absolute;
    top: 40%;
    left: 30px;
    width: 30px;
    height: 30px;
    opacity: 1;
    @include media-breakpoint-down(lg){
      left: 15px;
    }
    @include media-breakpoint-down(sm){
      top: 42%;
      left: 0;
    }
  }
  .my-icon {
    background: transparent;
    img{
      height: 36px;
      @include media-breakpoint-down(md){
        height: 30px;
      }
      @include media-breakpoint-down(sm){
        height: 24px;
      }
    }
  }
}
//slide end//