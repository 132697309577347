//color theme//
$main-clr:       #9cca4e;
$s-main-clr:     #62707c;
$l-main-clr:     #3e6300;
$p-clr:          #666666;
$warning:        #fdd51c;
$green:          #719632;
$red:            #ff5959;
$secondary:      $gray-600 !default;
$dark:           #3a3a3a;
$black:          #000;
$l-gray:         #cccccc;
$light:          #f0f7e5;
$white:          #fff;
$pro-card:       #ebf3de;
$light-clr:      #e4e8ef;
//border theme//
$border-theme:   1px solid #9cca4e66;
$border-black:   1px solid $dark;
$border-main:    1px solid $main-clr;
$border-s-main:  1px solid $s-main-clr;
$border-light:   1px solid $light-clr;
//shadow theme//
$pro-shadow:     0 5px 30px #00000029;
$my-shadow:      0 0 20px #00000016;