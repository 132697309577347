//Please import All plugins in plugins.scss file
@import "./plugins";
//icon section//
.icon-section{
  padding: 60px 0;
  @include media-breakpoint-down(lg){
    padding: 40px 0;
  }
  @include media-breakpoint-down(md){
    padding: 40px 0;
  }
  @include media-breakpoint-down(sm){
    padding: 0 0 40px 0;
  }
  .my-row{
    @include media-breakpoint-down(sm){
      flex-flow: row;
    }
  }
  .my-icon{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 8px;
    @include media-breakpoint-down(sm){
      display: block;
      text-align: center;
      padding: 24px 2px 0 2px;
    }
    li{
      img{
        width: 55px;
        @include media-breakpoint-down(lg){
          width: 50px;
        }
        @include media-breakpoint-down(md){
          width: 40px;
        }
        @include media-breakpoint-down(sm){
          height: 32px;
          width: auto;
        }
      }
      .icon-ul{
        padding-left: 16px;
        @include media-breakpoint-down(sm){
          padding-left: 0;
        }
        li{
          display: block;
          h5{
            font-weight: 600;
            color: $dark;
            margin-bottom: 0;
            @include transition;
            @include media-breakpoint-down(lg){
              font-size: 20px;
            }
            @include media-breakpoint-down(md){
              font-size: 18px;
            }
            @include media-breakpoint-down(sm){
              padding-top: 8px;
              font-size: 16px;
            }
            &:hover{
              color: $main-clr;
            }
            span{
              @include media-breakpoint-down(sm){
                display: none;
              }
            }
          }
          .icon-p{
            font-size: 16px;
            @include media-breakpoint-down(lg){
              font-size: 14px;
            }
            @include media-breakpoint-down(sm){
              display: none;
            }
          }
        }
      }
    }
  }
}
//icon section end//

//banner section//
.banner-section{
  .banner{
    width: 100%;
    @include media-breakpoint-down(sm){
      margin-bottom: 32px;
    }
    img{
      width: 100%;
      border-radius: 4px;
      border: 5px solid $main-clr;
      @include media-breakpoint-down(md){
        border: 4px solid $main-clr;
      }
    }
  }
  .my-carousel{
    margin-bottom: 40px;
    @include media-breakpoint-down(lg){
      margin-bottom: 30px;
    }
    @include media-breakpoint-down(md){
      margin-bottom: 24px;
    }
  }
}
//banner section end//

//pro-section//
.pro-section{
  .my-heading{
    margin: 0 !important;
  }
  .pro-tab{
    position: relative;

  }
  .tab-area {
    text-align: center;
    .nav-tabs {
      padding-bottom: 10px;
      display: flex;
      justify-content: center;
      border: none;
      .nav-item {
        &:not(:last-child) {
          padding: 0;
        }
        .nav-link {
          display: flex;
          font-size: 16px;
          font-weight: 600;
          align-items: center;
          border: none !important;
          border-bottom: 2px solid transparent !important;
          border-radius: 0;
          padding: 0 0 6px 0;
          margin: 4px 24px;
          color: $dark;
          //box-shadow: 0 1px 16px #00000020;
          background-color: $white;
          @include transition;
          @include media-breakpoint-down(sm){
            margin: 4px 12px;
          }
          &.active, &:hover {
            color: $green;
            border-bottom: 2px solid $green !important;
            .my-icon-01{
              display: none;
            }
            .my-icon-02{
              display: block;
            }
          }
          img{
            max-width: 22px;
            margin-right: 4px;
            @include media-breakpoint-down(sm){
              max-width: 20px;
            }
          }
          .my-icon-02{
           display: none;
          }
        }
      }
    }
    .tab-pane {
      padding: 0;
    }
  }

  .grid-4{
    @include media-breakpoint-down(md){
      display: none;
    }
  }
  .th-i{
    @include media-breakpoint-down(md){
      display: none;
    }
  }
  .vertical-i{
    display: none;
    @include media-breakpoint-down(md){
      display: block;
    }
  }
}
.pro-area{
  .pro-card{
    text-align: center;
    .pro-img{
      img{
        border: none;
      }
    }
  }
}
//pro section end//

//purpose area//
.purpose-area{
  width: 100%;
  background-color: $main-clr;
  .purpose-text{
    padding: 12px 32px 32px 60px;
    text-align: center;
    @include media-breakpoint-down(lg){
      padding: 12px 20px 32px 32px;
    }
    @include media-breakpoint-down(md){
      padding: 12px 15px 24px 15px;
    }
    h4{
      color: $white;
      margin: 24px 0;
      @include media-breakpoint-down(lg){
        font-size: 28px;
        margin: 16px 0;
      }
      @include media-breakpoint-down(md){
        font-size: 24px;
      }
    }
    p{
      color: $white;
    }
  }
  .purpose-img{
    height: 100%;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
  }
}
//purpose area end//

//mobile section//
.mobile-section{
  width: 100%;
  overflow: hidden;
  padding: 40px 0;
  .mobile-text{
    padding-top: 56px;
    text-align: center;
    @include media-breakpoint-down(lg){
      padding-top: 32px;
    }
    @include media-breakpoint-down(md){
      padding-top: 20px;
    }
    h4{
      font-weight: 600;
      color: $dark;
      @include media-breakpoint-down(lg){
        font-size: 28px;
      }
      @include media-breakpoint-down(md){
        font-size: 24px;
      }
    }
    .mobile-ul{
      padding-top: 24px;
      @include media-breakpoint-down(lg){
        padding-top: 20px;
      }
      @include media-breakpoint-down(md){
        padding-top: 12px;
      }
      li{
        display: inline-block;
        img{
          width: 120px;
          margin: 2px;
          @include media-breakpoint-down(lg){
            width: 100px;
          }
        }
      }
    }
  }
}
//mobile section//

//.....login page.....//
.login-area{
  text-align: center;
  .my-form{
    .or-form{
      margin: 20px 0 16px 0;
    }
    .form-group{
      .or-text{
        position: relative;
        color: $dark;
        &:after{
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          border-top: $border-s-main;
          width: 100%;
          z-index: -1;
        }
        span{
          background-color: $white;
          padding: 0 12px;
        }
      }
      .continue-a{
        margin-bottom: 4px;
        color: $dark;
      }
      .login-icons{
        i{
          color: #fff;
          padding: 12px;
          border-radius: 50%;
          margin: 2px;
          width: 40px;
        }
      }
    }
  }
}
//.....login page end.....//

//.....location page.....//
.location-area{
  .input-group{
    width: 100%;
    margin-bottom: 20px;
    @include media-breakpoint-down(md){
      margin-bottom: 12px;
    }
    @include media-breakpoint-down(sm){
      margin-bottom: 0;
    }
    .my-search-box{
      font-size: 16px;
      color: $dark;
      height: 48px;
      margin-left: 12px;
      border-radius: 0;
      border: none;
      border-right: none;
      background-color: #edf0f4 !important;
      @include media-breakpoint-down(md){
        margin-left: 0;
      }
      @include media-breakpoint-down(sm){
        height: 38px;
      }
      &:hover, &:active, &:focus{
        box-shadow: none;
        outline: none;
      }
    }
    .my-search-btn{
      color: $dark;
      padding: 0 16px 0 16px;
      height: 48px;
      border: none;
      border-radius: 0;
      background-color: $main-clr;
      @include transition;
      @include media-breakpoint-down(sm){
        height: 38px;
      }
      i{
        font-size: 16px;
        color: $dark;
      }
      &:hover, &:active, &:focus{
        border: none;
        box-shadow: none;
        outline: none;
      }
    }
  }
  .location-card{
    max-height: 530px;
    overflow-y: auto;
    padding: 0 16px 0 0;
    @include media-breakpoint-down(md){
      max-height: 100%;
      overflow-y: hidden;
      padding: 0;
    }
    .my-ul{
      display: flex;
      padding: 16px;
      margin: 12px 0 12px 12px;
      border-radius: 4px;
      background-color: $white;
      box-shadow: $my-shadow;
      @include media-breakpoint-down(lg){
        padding: 16px 12px;
      }
      @include media-breakpoint-down(md){
        margin: 16px 4px;
      }
      @include media-breakpoint-down(sm){
        display: block;
        padding: 0;
        margin: 24px 4px;
      }
      .my-li{
        img{
          width: 180px;
          height: 100%;
          object-fit: cover;
          object-position: top;
          border-radius: 4px;
          @include media-breakpoint-down(sm){
            width: 100%;
            height: 140px;
            border-radius: 4px 4px 0 0;
          }
        }
        .my-li-ul{
          padding-left: 16px;
          @include media-breakpoint-down(lg){
            padding-left: 12px;
          }
          @include media-breakpoint-down(sm){
            padding: 20px 16px;
          }
          h6{
            font-weight: 600;
            color: $dark;
            @include media-breakpoint-down(sm){
              font-size: 18px;
            }
          }
          .add-li{
            margin: 8px 0;
            .add-text{
              font-size: 16px;
              color: $dark;
              @include transition;
              @include media-breakpoint-down(sm){
                font-size: 15px;
              }
              &:hover{
                color: $main-clr;
              }
              i{
                color: $main-clr;
              }
            }
          }
          p{
            font-size: 14px;
            line-height: 18px;
            color: $s-main-clr;
            margin-bottom: 12px;
          }
          .btn-li{
            .btn-ul{
              li{
                display: inline-block;
              }
            }
            .read-more{
              font-size: 13px;
              color: $black !important;
              padding: 8px 12px;
              margin-right: 4px;
              border-radius: 0;
              @include media-breakpoint-down(lg){
                font-size: 11px;
                padding: 8px;
              }
              @include media-breakpoint-down(sm){
                font-size: 12px;
                padding: 8px 10px;
              }
            }
          }
        }
      }
    }
  }
}
.map{
  width: 100%;
  iframe{
    width: 100%;
    height: 600px;
    border-radius: 4px;
    border: none !important;
  }
}
.my-map{
  @include media-breakpoint-down(md){
    display: none;
  }
}
.your-map{
  @include media-breakpoint-down(md){
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  @include media-breakpoint-down(sm){
    display: block;
  }
  .map-btn{
    display: none;
    font-size: 16px;
    color: $dark;
    border-radius: 0;
    border: none;
    padding: 12px;
    width: 500px;
    margin-right: 20px;
    margin-bottom: 12px;
    background-color: #d9ebbc;
    outline: none !important;
    box-shadow: none !important;
    @include media-breakpoint-down(md){
      display: block;
    }
    @include media-breakpoint-down(sm){
      width: auto;
      padding: 6px 12px;
      margin-right: 0;
    }
  }
}
.miles{
  font-size: 14px;
  color: $green !important;
  margin: 4px 0;
  display: block;
  width: 100%;
}
//map modal//
#map-modal{
  background-color: #0000000d;
  z-index: 999999;
  @include media-breakpoint-down(sm){
    background-color: #00000061;
  }
  .modal-dialog{
    max-width: 90%;
    @include media-breakpoint-down(sm){
      max-width: 100%;
    }
  }
  .modal-content{
    border: none !important;
    background-color: transparent !important;
  }
  .modal-header{
    padding: 0 0 8px 0;
    border: none;
  }
  .modal-body{
    padding: 0;
    .map{
      margin: 0;
      padding: 0;
      iframe{
        width: 100%;
        height: 480px;
        border-radius: 0 !important;
        border: 3px solid $white;
        margin: 0;
      }
    }
  }
  .close{
    opacity: 1;
  }
  .close-icon{
    opacity: 1;
    i{
      font-size: 16px;
      color: $white !important;
    }
  }
}
//.....location page end.....//

//.....order page.....//
//basket card//
.delivery-right.dropdown {
  position: relative;
  &:hover{
    .dropbtn {
      background-color: $main-clr;
    }
    .dropdown-content {
      display: block;
    }
  }
  .dropbtn{
    outline: none;
    box-shadow: none;
  }
  .dropdown-content {
    display: none;
    position: absolute;
    right: 0;
    text-align: left;
    min-width: 340px;
    border-radius: 4px;
    padding: 12px;
    background-color: $white;
    box-shadow: $pro-shadow;
    z-index: 999;
    @include media-breakpoint-down(md){
      min-width: 280px;
    }
    @include media-breakpoint-down(sm){
      left: 0;
    }
  }
}
.basket-card{
  @include media-breakpoint-down(sm){
    padding: 0 16px 16px 16px;
  }
  .ul-delivery{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
    @include media-breakpoint-down(sm){
      display: block;
    }
    li{
      .order-h5{
        font-size: 21px;
        font-weight: 600;
        color: $dark;
        text-align: left;
        padding: 8px 0;
        margin: 0 32px 0 0;
        @include media-breakpoint-down(md){
          font-size: 18px;
          margin: 0 12px 0 0;
        }
        @include media-breakpoint-down(sm){
          font-size: 20px;
          padding: 12px 0 4px 0;
          margin: 0 24px 0 0;
        }
      }
      .postcode{
        font-size: 14px;
        font-weight: 600;
        color: $s-main-clr;
        margin: 0;
      }
    }
  }
  .basket-pro{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: end;
    padding: 14px 0;
    border-bottom: $border-light;
    .basket-text{
      width: 100%;
      text-align: left;
      display: flex;
      justify-content: left;
      padding: 0;
      .pro-img{
        width: 80px;
        @include media-breakpoint-down(md){
          width: 70px;
        }
        img{
          width: 100%;
          border-radius: 4px;
          border: 1px solid #00000014;
          margin-top: -1px;
        }
      }
      .pro-text{
        width: 100%;
        margin-left: 8px;
        @include media-breakpoint-down(md){
          margin-left: 4px;
        }
        .pro-name{
          display: flex;
          justify-content: space-between;
          align-items: end;
          width: 100%;
          margin-bottom: 10px;
          .quantity-name{
            display: flex;
            .quantity-select{
              font-size: 10.6px;
              color: $dark;
              width: 36px;
              height: 18px;
              padding: 0;
              margin: 0 8px 0 0;
              border: 1px solid #b5b5b5;
              border-radius: 2px;
              outline: none;
              box-shadow: none;
            }
            a{
              h5{
                font-size: 14px;
                font-weight: 600;
                color: $dark;
                margin-bottom: 2px;
                @include transition;
                @include media-breakpoint-down(md){
                  font-size: 13px;
                }
              }
            }
          }
          h6{
            font-size: 14px;
            font-weight: 600;
            color: $green;
            margin: 0 0 2px 16px;
            @include media-breakpoint-down(md){
              font-size: 13px;
            }
          }
        }
        p{
          font-size: 13px;
          line-height: 16px;
          color: $p-clr;
          margin: 0 0 2px 0;
          @include media-breakpoint-down(md){
            font-size: 12px;
          }
        }
      }
    }
    .basket-cut{
      padding: 0 4px 0 16px;
      margin-top: -4px;
    }
  }
  .subtotal{
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    font-size: 15px;
    font-weight: 600;
    padding: 0 !important;
    margin: 8px 0 1px 0 !important;
    color: $red !important;
    span{
      font-size: 15px;
      font-weight: 500;
      color: $red;
      padding: 0 0 0 4px;
    }
  }
  .form-instructions{
    font-size: 13px !important;
    margin: 12px 0 8px 0;
    border-radius: 0;
    border:none;
    background-color: #edf0f4 !important;
    outline: none;
    box-shadow: none;
  }
  small{
    margin-bottom: 8px;
  }
  .btn-checkout{
    font-size: 15px;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    margin-top: 8px;
    @include media-breakpoint-down(sm){
      font-size: 14px;
    }
  }
}
.basket-ul{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
  margin: 0 0 8px 0;
  border-radius: 50px;
  background-color: #ebf4dc;
  li{
    width: 100%;
    a{
      color: $white;
      width: 100%;
      border: none;
      outline: none;
      box-shadow: none;
      ul{
        display: flex;
        justify-content: left;
        align-items: center;
        li{
          text-align: left;
          width: auto;
          img{
            width: 40px;
            padding: 5px;
            margin-right: 8px;
            border-radius: 50px;
            border: 2px solid $main-clr;
            background-color: #ebf4dc;
            @include media-breakpoint-down(md){
              width: 34px;
              padding: 4px;
              margin-right: 4px;
            }
          }
          p{
            font-size: 14px;
            font-weight: 600;
            color: $black;
            margin: 0 0 -8px 0;
            @include media-breakpoint-down(md){
              font-size: 13px;
            }
          }
          span{
            font-size: 11px;
            font-weight: 600;
            color: $dark;
            margin: 0;
            @include media-breakpoint-down(md){
              font-size: 10px;
            }
          }
        }
      }
    }
    .basket-delivery{
      padding: 1px 14px 1px 4px;
      border-radius: 50px;
      background-color: $main-clr;
    }
  }
}
//basket card end//

//delivery banner//
.delivery-banner{
  background: url("../../assets/image/banner/banner-08.jpg");
  background-size: cover;
  background-position: center;
  padding: 32px 0;
  @include media-breakpoint-down(md){
    padding: 28px 0;
  }
  @include media-breakpoint-down(sm){
    padding: 16px 0;
  }
  .delivery-text{
    .delivery-a{
      font-size: 18px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: $warning;
      @include media-breakpoint-down(md){
        font-size: 16px;
      }
      @include media-breakpoint-down(sm){
        font-size: 14px;
      }
      span{
        font-size: 16px;
        font-weight: 500;
        text-transform: capitalize;
        color: $white;
        display: block;
        @include media-breakpoint-down(md){
          font-size: 15px;
        }
        @include media-breakpoint-down(sm){
          display: initial;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
    a{
      font-size: 16px;
      font-weight: 500;
      color: $white;
      box-shadow: none;
      @include media-breakpoint-down(md){
        font-size: 15px;
      }
      i{
        margin-left: 2px;
      }
    }
    p{
      color: $white;
      margin-top: 12px;
      @include media-breakpoint-down(lg){
        margin-top: 8px;
      }
      @include media-breakpoint-down(md){
        font-size: 12px;
        margin-top: 1px;
      }
    }
  }
  .delivery-right{
    text-align: right;
    @include media-breakpoint-down(sm){
      text-align: left;
      margin-top: 8px;
      display: none;
    }
    .basket{
      font-size: 16px;
      text-transform: uppercase;
      letter-spacing: 1px;
      border-radius: 0;
      border: 1px solid #fff3;
      padding: 6px 16px;
      background-color: #ffffff20;
      @include transition;
      @include media-breakpoint-down(md){
        font-size: 13px;
      }
    }
  }
}
//delivery banner end//

//pro modal//
.pro-modal{
  .modal-content{
    height: 90%;
    padding-bottom: 70px;
    position: relative;
    @include media-breakpoint-down(sm){
      padding-bottom: 116px;
    }
    .modal-body{
      overflow: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
      .product_img{
        text-align: center;
        img{
          max-width:20rem;
          display: inline-block;
          @include media-breakpoint-down(md){
            max-width:16rem;
          }
        }
      }      
    }
    .form-control{
      border:$border-theme;
      outline: none !important;
      box-shadow: none !important;
    }
    .modal-title{
      font-size: 22px;
      line-height: 28px;
      font-weight: 600;
      color: $dark;
      text-align: left;
      padding-top: 15px;
      padding-bottom: 12px;
      margin-bottom: 12px;
      position: sticky;
      top: -15px;
      background: $white;
      z-index: 2;
      border-bottom: 1px solid rgba($dark, 0.1);
      @include media-breakpoint-down(md){
        font-size: 20px;
      }
    }
    .description{
      font-size: 14px;
      line-height: 20px;
      font-weight: 300;
      color: $dark;
      text-align: left;
      padding-bottom: 10px;
    }
    .close_btn{
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: 20px;
      color: $dark;
      z-index: 3;
      background: transparent;
    }
    .option_select{
      .option_toggler{
        position: relative;
        padding: 10px 15px;
        background: rgba($l-main-clr, 0.2);
        border-radius: 3px;
        margin: 10px 0;
        h3{
          font-size: 16px;
          line-height: 21px;
          color: $dark;
          font-weight: 600;
          margin: 0;
          text-align: left;
          span{
            font-size: 12px;
            line-height: 15px;
            color: rgba($dark, 0.6);
            transition: all 0.3s ease-in-out;
          }
        }
        i{
          transition: all 0.3s ease-in-out;
        }
        &.active{
          i.fa-chevron-up{
            transform: rotate(180deg);
          }
          + .optionWpr{
            height: auto;
          }
        }
      }
      
      .optionWpr{
        height: 0;
        overflow: hidden;
        li{
          flex: 1 0 33.333%;
          text-align: center;
          border-right: 1px solid rgba($dark, 0.12);
          &.lg img{
            max-width: 100px;
            height: auto;
            margin-bottom: 7px;        
          }
          &.md img{
            max-width: 80px;
            height: auto;
            margin-bottom: 7px;  
          }
          &.sm img{
            max-width: 70px;
            height: auto;
            margin-bottom: 7px;  
          }
          &:last-child{
            border: 0;
          }
        }
        label{
          cursor: pointer;
          margin: 0;
          span{
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 15px;
            color: $dark;
            line-height: 18px;
            padding: 4px 0;
            i.fa-check-circle{
              display: none;
              color: $green;
            }
            strong{
              color: $green;
              padding-left: 8px;
            }
          }
          input[type=radio]:checked{
            ~ span{
              i.fa-circle{
                display: none;
              }
              i.fa-check-circle{
                display: block;
              }
            }
          }
          input[type=checkbox]:checked{
            ~ span{
              i.fa-circle{
                display: none;
              }
              i.fa-check-circle{
                display: block;
              }
            }
          }
        }
        .multi_selction{
          display: none;
        }
      }
    }
    .modal-footer{
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      background-color: $white;
      z-index: 9;
      .total-p{
        font-size: 16px;
        font-weight: 600;
        color: $dark;
        padding: 0 12px 0 0;
        @include media-breakpoint-down(sm){
          display: block;
          width: 100%;
          padding: 0 0 8px 0;
        }
        span{
          font-size: 20px;
          color: $green;
        }
      }
      .order_qty{
        width: 130px;
        background: rgba($dark, 0.1);
        border-radius: 0;
        overflow: hidden;
        li{
          i{
            font-size: 12px;
            line-height: 38px;
            color: $warning;
          }
          input{
            background: transparent;
            border: 0;
            width: 40px;
            height: 40px;
            padding: 0 5px;
            font-size: 13px;
            font-weight: 600;
            line-height: 25px;
            margin: 0 5px;
            color: $black;
            vertical-align: top;
            text-align: center;
            outline: none;
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }
          &:first-child, &:last-child{
            background: $dark;
            width: 40px;
            height: 40px;
            text-align: center;
            cursor: pointer;
          }
        }
      }
      .addOrder_btn{
        position: static;
        width: 250px;
        background: $main-clr;
        height: 40px;
        font-size: 15px;
        line-height: 30px;
        color: $black;
        font-weight: 300;
        border-radius: 0;
        padding: 4px 6px;
        margin: 0 17px 0 8px;
        flex: 1;
        @include transition;
        &:hover{
          background: $s-main-clr;
          color: $white;
        }
        @media(max-width:450px){
          width: 130px;
        }
      }
    }
  }
  .modal-dialog{
    max-width: 700px;
    @include media-breakpoint-down(md){
      max-width: 600px;
    }
  }
  .my-quantity {
    input{
      font-size: 14px;
      color: $black;
    }
    .input-group{
      flex-wrap:nowrap;
    }
    .plus-minus-input {
      align-items: center;
      height: 100%;
      .input-group-field {
        text-align: center;
        padding: 2px;
        margin: 0 1px;
        width: 36px;
        border: $border-theme;
        &::-webkit-inner-spin-button,
        ::-webkit-outer-spin-button {
          appearance: none;
        }
      }
      .input-group-button {
        .circle {
          font-size: 8px;
          color: $white;
          background-color: transparent;
        }
      }
    }
  }
}
//pro modal end//

//mobile basket//
.mobile-basket{
  display: none;
  @include media-breakpoint-down(sm){
    display: block;
  }
  .mobile-basket-btn{
    font-size: 16px;
    font-weight: 600;
    color: $dark;
    border-radius: 0;
    padding: 4px 12px;
    width: 100%;
    background-color: $main-clr;
    box-shadow: 0 0 40px #00000040;
  }
  //mobile basket sidenav//
  .sidenav {
    position: fixed;
    top: 0;
    right: 0;
    padding: 0;
    height: 100%;
    width: 0;
    background-color: $white;
    overflow-x: hidden;
    box-shadow: $pro-shadow;
    z-index: 99999;
    @include transition;
    .closebtn {
      position: absolute;
      top: 0;
      right: 10px;
      font-size: 24px;
      font-weight: 600;
      margin-left: 40px;
      color: $dark;
      @include transition;
      &:hover{
        color: $main-clr;
      }
    }
  }
  .menu-icon {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 9;
  }
  //mobile basket sidenav end//
}
//mobile basket end//
//.....order page.....//

//.....my order page.....//
.shopping-cart {
  width: 100%;
  overflow: hidden;
  text-align: center;
  .my-table {
    border: none;
    margin-bottom: 0;
    background-color: $white;
    box-shadow: $pro-shadow;
    .table {
      margin-bottom: 0;
      .thead-dark {
        @include media-breakpoint-down(sm){
          width: 100%;
          position: fixed;
          top: 0;
          left: 0;
          padding: 45px 0 0 0;
          background-color: $white;
        }
        .thead-dark-tr{
          @include media-breakpoint-down(sm){
            width: 100%;
            padding: 0;
            display: flex;
            background-color: $main-clr;
          }
        }
        th {
          font-size: 16px;
          font-weight: 600;
          text-transform: uppercase;
          letter-spacing: 1px;
          color: $black;
          border: none;
          padding: 20px 8px;
          background-color: $main-clr;
          @include media-breakpoint-down(md){
            padding: 16px 8px;
            font-size: 15px;
            background-color: #efefef;
          }
          @include media-breakpoint-down(sm){
            padding: 12px 4px;
            font-size: 13px;
            width: 25%;
          }
        }
      }
      .my-tbody {
        .my-tr{
          background-color: $white;
          box-shadow: 0 5px 30px #00000020;
          @include media-breakpoint-down(sm){
            display: flex;
            align-items: end;
            width: 100%;
            box-shadow: 0 4px 20px #00000020;
          }
        }
        th {
          border: none;
          padding: 20px 0;
          img {
            height: 110px;
            padding: 0 0 0 8px;
            border-radius: 4px;
            border: none !important;
            @include media-breakpoint-down(md) {
              height: 80px;
            }
          }
        }
        td {
          padding: 32px 8px;
          border: none;
          @include media-breakpoint-down(md){
            padding: 24px 8px;
          }
          @include media-breakpoint-down(md){
            padding: 16px 4px 12px 4px;
            width: 25%;
          }
          h4 {
            font-size: 20px;
            font-weight: 500;
            color: $dark;
            margin-bottom: 2px;
            @include media-breakpoint-down(md) {
              font-size: 18px;
            }
            @include media-breakpoint-down(sm) {
              font-size: 16.5px;
            }
          }
          .order-no{
            font-size: 17px;
            font-weight: 500;
            color: $main-clr;
            margin-bottom: 0;
            @include media-breakpoint-down(md) {
              font-size: 15px;
            }
          }
          .my-date{
            font-size: 14px;
            color: $dark;
            @include media-breakpoint-down(md){
              font-size: 12.5px;
            }
          }
          h6 {
            color: $dark;
            font-size: 15px;
            font-weight: 500;
            margin-top: 4px;
            @include media-breakpoint-down(md) {
              font-size: 14px;
            }
          }
          a{
            p{
              font-size: 14px;
              font-weight: 500;
              line-height: 20px;
              text-transform: capitalize;
              margin-bottom: 2px;
            }
          }
          .my-h6{
            line-height: 26px;
          }
          h5 {
            color: $dark;
            font-size: 17px;
            font-weight: 500;
            @include media-breakpoint-down(md) {
              font-size: 15px;
            }
          }
          a {
            font-size: 17px;
            font-weight: 500;
            color: $dark;
            @include transition;
            @include media-breakpoint-down(md) {
              font-size: 15px;
            }
            i {
              font-size: 17px;
              color: $dark;
              @include transition;
              @include media-breakpoint-down(md) {
                font-size: 15px;
              }
            }
          }
          .cut{
            i{
              font-size: 17px;
              color: $red;
              @include media-breakpoint-down(md){
                font-size: 15px;
              }
            }
          }
        }
      }
    }
  }
}
.cut-td{
  @include media-breakpoint-down(sm){
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .cut{
    display: none;
    @include media-breakpoint-down(sm){
      display: block;
    }
  }
}
.cut{
  i{
    font-size: 14px;
    color: $red !important;
    @include media-breakpoint-down(md){
      font-size: 13px;
    }
  }
}
.reorder{
  @include media-breakpoint-down(sm){
    font-size: 13px !important;
  }
}
.eye{
  i{
    font-size: 18px;
    color: $main-clr !important;
    padding: 7px 6px;
    border: $border-main;
    border-radius: 50px;
    @include media-breakpoint-down(md){
      font-size: 16px;
    }
    @include media-breakpoint-down(sm){
      font-size: 15px;
      padding: 0;
      border: none;
    }
  }
}
.no-td{
  @include media-breakpoint-down(sm){
    display: none;
  }
}
//table scrollbar//
.table-responsive::-webkit-scrollbar {
  -webkit-appearance: none;
}
.table-responsive::-webkit-scrollbar:vertical {
  width: 10px;
}
.table-responsive::-webkit-scrollbar:horizontal {
  height: 10px;
}
.table-responsive::-webkit-scrollbar-thumb {
  background-color: $main-clr;
  border-radius: 10px;
  border: 2px solid $pro-card;
}
.table-responsive::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: $pro-card;
}
//table scrollbar end//
.checkout-btn {
  text-align: left;
  padding: 40px 0 0 0;
  margin: 0;
  @include media-breakpoint-down(sm) {
    padding: 20px 0 0 0;
  }
  li {
    display: inline-block;
    margin-right: 15px;
    @include media-breakpoint-down(sm) {
      margin-right: 10px;
      margin-top: 10px;
    }
  }
}
//.....my order page end.....//

//delivery modal//
#delivery-modal{
  background-color: #0000000d;
  z-index: 999999;
  @include media-breakpoint-down(sm){
    background-color: #00000061;
  }
  .modal-header{
    padding: 2px 16px 0 16px;
    border: none;
  }
  .modal-body{
    padding: 0 16px 16px 16px;
  }
  .close{
    opacity: 1;
  }
  .close-icon{
    opacity: 1;
    i{
      font-size: 16px;
      color: $black !important;
    }
  }
  .modal-title{
    font-size: 24px;
    font-weight: 600;
    color: $green;
    padding-bottom: 4px;
    margin-bottom: 20px;
    border-bottom: $border-light;
  }
  .form-control{
    margin-bottom: 15px;
    height: 48px;
    box-shadow: none;
    outline: none;
    border-radius: 0 !important;
    border:none;
    background-color: #edf0f4 !important;
    font-size: 16px;
    color: #6c757d;
    @include media-breakpoint-down(md){
      margin-bottom: 10px;
    }
    &:focus{
      outline: none;
      border-color: $main-clr;
    }
  }
  .input-li{
    padding: 0 4px;
  }
  .location-modal{
    display: flex;
    justify-content: space-between;
    align-items: start;
    padding-bottom: 16px;
    @include media-breakpoint-down(md){
      padding-bottom: 12px;
    }
    h6{
      font-size: 16px;
      font-weight: 600;
      color: $dark;
      @include media-breakpoint-down(sm){
        font-size: 14px;
      }
      i{
        color: $main-clr;
        margin: 0 4px 8px 0;
      }
      span{
        display: block;
        width: 100%;
        font-size: 14px;
        font-weight: 500;
        color: $p-clr;
        @include media-breakpoint-down(sm){
          font-size: 12.6px;
        }
      }
    }
    .read-more{
      font-size: 13px !important;
      color: $dark !important;
      padding: 5px 12px !important;
      width: fit-content;
      background-color: #9cca4e66 !important;
      outline: none;
      box-shadow: none;
      @include media-breakpoint-down(md){
        font-size: 12px !important;
      }
    }
  }
  .modal-btn{
    .read-more{
      margin: 0 4px;
      line-height: normal;
      height: 48px !important;
      padding: 17px 3px !important;
      color: $dark !important;
    }
    .read-more-close{
      font-weight: 500;
      background-color: #f5cd16;
      &:hover, &:active, &.active{
        background-color: $s-main-clr;
      }
    }
  }
  #myDIV{
    display: none;
    width: 100%;
  }
}
//delivery modal end//

//fixed header//
.fixed-header{
  position: fixed !important;
  top: 0;
  left: 0;
  padding: 4px 80px !important;
  width: 100%;
  background-color: $white;
  box-shadow: 0 5px 30px #00000014;
  z-index: 99;
  @include media-breakpoint-down(lg){
    padding: 4px 50px !important;
  }
  @include media-breakpoint-down(sm){
    padding: 4px 35px !important;
  }
  .btn-icon{
    top: 11px !important;
    @include media-breakpoint-down(sm){
      top: 9px !important;
    }
  }
  .goPrev{
    left: 60px !important;
    @include media-breakpoint-down(lg){
      left: 30px !important;
    }
    @include media-breakpoint-down(sm){
      left: 15px !important;
    }
  }
  .goNext{
    right: 60px !important;
    @include media-breakpoint-down(lg){
      right: 30px !important;
    }
    @include media-breakpoint-down(sm){
      right: 15px !important;
    }
  }
}
//fixed header end//

//.....about page.....//
.about-area {
  text-align: center;
  .my-heading {
    margin-bottom: 25px;
  }
}
//.....about page end.....//

//.....Contact page.....//
.contact-page {
  width: 100%;
  overflow: hidden;
  h4{
    font-weight: 600;
    color: $dark;
    margin-bottom: 4px;
    @include media-breakpoint-down(md){
      font-size: 28px;
    }
    @include media-breakpoint-down(sm){
      font-size: 24px;
    }
  }
  .contact-p{
    margin-bottom: 32px;
    @include media-breakpoint-down(md){
      margin-bottom: 24px;
    }
  }
  .branch-name{
    font-size: 22px;
    font-weight: 600;
    color: $dark;
    margin: 12px 0 8px 0;
  }
  .branch-card{
    margin-bottom: 20px;
    .contact-ul {
      padding: 24px;
      border-radius: 4px;
      background-color: $white;
      box-shadow: $my-shadow;
      @include media-breakpoint-down(lg){
        padding: 20px 15px;
      }
      @include media-breakpoint-down(md){
        padding: 24px;
      }
      @include media-breakpoint-down(sm){
        padding: 20px 15px;
      }
      li {
        list-style: none;
        display: flex;
        align-items: center;
        padding-bottom: 8px;
        i {
          text-align: center;
          font-size: 16px;
          color: $main-clr;
          width: 34px;
          height: 34px;
          padding-top: 8px;
          margin-top: 2px;
          margin-right: 15px;
          border: 1px solid $main-clr;
          border-radius: 50%;
          @include media-breakpoint-down(lg){
            margin-right: 10px;
          }
        }
        .my-text {
          h6 {
            margin: 5px 0;
            font-size: 16px;
            font-weight: 600;
            color: $dark;
          }
        }
      }
    }
  }
  .my-form{
    margin: 40px 0 0 0;
    @include media-breakpoint-down(lg) {
      margin: 20px 0 0 0;
    }
  }
  .map{
    width: 100%;
    height: 100%;
    padding: 40px 0 0 0;
    @include media-breakpoint-down(lg) {
      padding: 20px 0 0 0;
    }
    @include media-breakpoint-down(md) {
      padding: 20px 0;
      height: 400px;
    }
    iframe{
      width: 100%;
      height: 100% !important;
    }
  }
}
//.....Contact page end.....//

//.....FAQ.....//
.faq-area{
  width: 100%;
  position: relative;
  .content{
    padding: 40px 32px;
    border: $border-light;
    border-radius: 0;
    background-color: $white;
    @include media-breakpoint-down(sm){
      padding: 24px 16px;
    }
    h4{
      font-weight: 600;
      color: $dark;
      margin-bottom: 8px;
      @include media-breakpoint-down(md){
        font-size: 28px;
      }
      @include media-breakpoint-down(sm){
        font-size: 24px;
      }
    }
    p{
      font-size: 14px;
      line-height: 20px;
      @include media-breakpoint-down(md){
        font-size: 13px;
      }
    }
    .accordion {
      margin-bottom: 20px;
      .card{
        border: none;
        border-radius: 0;
        flex-direction: column;
        padding: 0;
        margin-top: 20px;
        @include media-breakpoint-down(md){
          margin-top: 12px;
        }
        @include media-breakpoint-down(sm){
          margin-top: 8px;
        }
        a.card-header{
          padding: 16px 0;
          border: none;
          background-color: transparent;
          font-size: 18px;
          font-weight: 600;
          color: $dark;
          @include media-breakpoint-down(md){
            font-size: 16px;
          }
          @include media-breakpoint-down(sm){
            font-size: 14px;
          }
          &:hover{
            text-decoration: none;
          }
          span{
            font-weight: 600;
            color: $dark;
            margin-right: 4px;
            font-size: 18px;
            @include media-breakpoint-down(md){
              font-size: 16px;
            }
          }
        }
        .card-body{
          padding: 12px 0;
          border: none;
          background-color: transparent;
          p{
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 0;
            @include media-breakpoint-down(md){
              font-size: 13px;
            }
          }
        }
        .faq-ul{
          padding: 0;
          margin: 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: $border-light;
          li{
            display: flex;
            justify-content: space-between;
            i{
              font-size: 20px;
              color: $main-clr;
              padding: 0 4px;
            }
          }
        }
      }
    }
  }
  .collapsed{
    @include transition;
    i{
      @include transition;
      transform: rotate(180deg);
    }
  }
}
//.....FAQ end.....//

//.....conditions area.....//
.conditions-area{
  .conditions{
    width: 100%;
    padding-bottom: 20px;
    h5{
      font-weight: 600;
      color: $dark;
      margin-bottom: 12px;
      @include media-breakpoint-down(md){
        font-size: 20px;
      }
    }
    p{
      margin-bottom: 12px;
    }
  }
}
//.....conditions area end.....//

//.....my account page.....//
.my-account{
  .icon-img{
    width: 22px;
    margin-right: 8px;
    @include media-breakpoint-down(lg){
      width: 20px;
      margin-right: 4px;
    }
  }
  .password{
    @include media-breakpoint-down(md){
      display: none;
    }
  }
  .card{
    border: none;
    border-radius: 0;
    padding: 12px 0;
    @include media-breakpoint-down(md){
      padding: 0;
    }
    .my-bio {
      width: 100%;
      overflow: hidden;
      text-align: center;
      border-radius: 4px;
      .my-img{
        position: relative;
        width: 100px;
        margin: 0 auto;
        @include transition;
        &:hover{
          .img-upload{
            opacity: 1;
          }
        }
        img {
          width: 100px;
          height: auto;
          border-radius: 50%;
          border: 4px solid $light-clr;
        }
        .img-upload{
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          margin: 0 auto;
          border-radius: 50px;
          width: 100px;
          height: 100px;
          background-color: #62707c4f;
          .file {
            position: relative;
            overflow: hidden;
            font-size: 16px;
            font-weight: 600;
            color: $dark;
            padding: 2px;
            margin: 34px;
            width: 32px;
            height: 32px;
            border-radius: 50px;
            background-color: $main-clr;
            @include transition;
            input {
              position: absolute;
              right: 0;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              opacity: 0;
            }
          }
        }
      }
      h6{
        font-weight: 600;
        color: $main-clr;
        padding-top: 16px;
        margin-bottom: 2px;
        @include media-breakpoint-down(lg){
          font-size: 18px;
        }
      }
      small{
        font-size: 14px;
        font-weight: 500;
        color: $s-main-clr;
      }
    }
    .card-header{
      padding: 0;
      border-radius: 0;
      border: none;
      background-color: $white;
      .nav-tabs{
        border: none;
        padding-top: 16px;
        .nav-item{
          padding: 4px 0;
          a{
            font-size: 16px;
            font-weight: 600;
            color: $black;
            border: none;
            border-radius: 0;
            padding: 12px 16px;
            border-bottom: $border-light;
            background-color: $white;
            @include media-breakpoint-down(lg){
              font-size: 14px;
              padding: 12px 8px;
            }
            &:hover, &:active, &.active{
              border-bottom: 1px solid transparent;
              background-color: $main-clr;
            }
          }
        }
      }
    }
    .card-body{
      padding: 0 !important;
    }
  }
  .my-table{
    border: $border-light;
    padding: 12px !important;
    @include media-breakpoint-down(md){
      border: none !important;
      padding: 0 !important;
    }
    @include media-breakpoint-down(sm){
      padding-top: 34px !important;
    }
  }
  @include media-breakpoint-down(md){
    .tab-content{
      position: fixed;
      top: 0;
      right: 0;
      width: 100%;
      height: 100vh;
      overflow: auto;
      padding-top: 48px;
      background-color: $white;
      z-index: 9999;
      display: none;
    }
    .tab-content.active{
      display: block;
    }
  }
  .tob-close-btn{
    display: none;
    font-size: 18px;
    font-weight: 600;
    color: $dark;
    position: fixed;
    top: 0;
    left: 0;
    text-align: center;
    padding: 10px 15px;
    margin-bottom: 8px;
    width: 100%;
    background-color: $main-clr;
    box-shadow: 0 0 6px #00000026;
    z-index: 99;
    opacity: 1;
    @include media-breakpoint-down(md){
      display: flex;
      align-items: center;
    }
    @include media-breakpoint-down(sm){
      text-align: left;
    }
    span{
      width: 100%;
    }
    .close{
      font-size: 20px;
      color: $dark;
      opacity: 1;
      text-shadow: none;
    }
  }
  .my-col-md{
    @include media-breakpoint-down(md){
      margin: 0 auto;
    }
  }
  .account-my-form{
    @include media-breakpoint-down(md){
      padding: 15px !important;
      box-shadow: none !important;
    }
    @include media-breakpoint-down(sm){
      text-align: left;
    }
    .read-more{
      @include media-breakpoint-down(sm) {
        text-align: center;
        width: 100%;
      }
    }
  }
  .my-bio-col{
    @include media-breakpoint-down(md){
      margin: 0 auto;
    }
  }
}
.contact-preferences{
  .contact-my-form{
    @include media-breakpoint-down(md){
      padding: 24px 20px !important;
      margin-top: 12px !important;
    }
    @include media-breakpoint-down(sm){
      padding: 20px 14px !important;
      margin-top: 0 !important;
      box-shadow: none !important;
    }
  }
  .my-form {
    .form-check{
      margin-bottom: 8px;
      text-align: left;
    }
    .form-group{
      h5{
        font-size: 22px !important;
        margin: 20px 0;
        @include media-breakpoint-down(sm){
          font-size: 18px !important;
          letter-spacing: 1px;
        }
      }
    }
  }
}
//account modal//
.account-modal{
  background-color: #0000000d;
  z-index: 999999;
  @include media-breakpoint-down(sm){
    background-color: #00000061;
  }
  .modal-body{
    padding: 16px 24px 32px 24px !important;
    @include media-breakpoint-down(sm){
      padding: 2px 16px 24px 16px !important;
    }
  }
  .modal-dialog{
    max-width: 560px !important;
  }
  .modal-area{
    width: 100%;
    padding: 16px;
    border-radius: 8px;
    border: $border-light;
    @include media-breakpoint-down(sm){
      padding: 12px 4px;
    }
  }
  .reorder-area{
    text-align: center;
    width: 100%;
    margin: 16px 0 0 0;
    .reorder-btn{
      font-size: 14px;
      color: $white;
      padding: 8px 24px;
      border-radius: 4px;
      background-color: $main-clr;
      @include transition;
      &:hover{
        background-color: $s-main-clr;
      }
    }
  }
  .modal-content{
    padding-bottom: 0 !important;
  }
  .order-size{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2px !important;
    padding: 12px 8px !important;
    border-radius: 0;
    border-bottom: $border-light;
  }
  .order-p{
    font-size: 17px;
    font-weight: 600;
    color: $s-main-clr;
    padding: 4px 0;
    margin-top: 20px;
    @include media-breakpoint-down(sm){
      font-size: 15px;
    }
    span{
      font-size: 16px;
      font-weight: 500;
      @include media-breakpoint-down(sm){
        font-size: 14px;
      }
    }
  }
  .address-area {
    margin-top: 20px;
    padding: 20px;
    border-radius: 8px;
    background-color: #f6f6f6;
    li {
      margin: 0 0 15px 0;
      @include media-breakpoint-down(md) {
        margin: 0 0 10px 0;
      }
      h6 {
        color: $s-main-clr;
        font-size: 16px;
        @include media-breakpoint-down(sm) {
          font-size: 14px;
        }
        span{
          font-weight: 600;
        }
      }
    }
  }
}
//account modal end//
//.....my account page end.....//

//.....checkout-section.....//
.checkout-section{
  .basket-card{
    padding: 32px;
    margin: 67px 0 0 0;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: $my-shadow;
    @include media-breakpoint-down(lg){
      padding: 32px 20px;
    }
    @include media-breakpoint-down(md){
      margin: 16px 0 0 0;
    }
    .ul-delivery{
      .order-h5{
        font-size: 24px;
        padding: 0;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 2px;
        color: $dark;
        @include media-breakpoint-down(md){
          font-size: 20px;
        }
      }
    }
    .subtotal-text{
      color: $dark !important;
      span{
        color: $dark !important;
        font-weight: 500;
      }
    }
    .checkout-img{
      display: none;
    }
  }
  .details {
    width: 100%;
    .card {
      border: none;
      border-radius: 0;
      .card-header {
        background-color: $white;
        margin: 0;
        padding: 0;
        border-radius: 0;
        border: none;
      }
      .table {
        margin-bottom: 0;
      }
    }
    .card-body {
      background-color: $white;
      margin: 0;
      padding: 0;
    }

  }
  .basket-ul{
    justify-content: left !important;
    padding: 0;
    border: none !important;
    background-color: transparent;
    .nav-item{
      margin-right: 16px;
    }
    li{
      width: auto;
      .basket-delivery{
        padding: 2px 14px 2px 4px !important;
        border: none;
        outline: none;
        box-shadow: none;
        background-color: #ebf4dc !important;
        @include transition;
        &:hover,&:active,&.active{
          background-color: $main-clr !important;
        }
      }
    }
  }
  .delivery-tab{
    text-align: left;
    .read-more{
      margin: 12px 0 8px 0;
      @include media-breakpoint-down(sm){
        width: 100% !important;
        text-align: center;
        margin: 8px 0 4px 0;
      }
    }
  }
  .guest-h5{
    margin: 24px 0 12px 0 !important;
  }
}
.schedule-ul {
  display: flex;
  justify-content: space-between;
  @include media-breakpoint-down(sm){
    display: block;
  }
  li {
    text-align: left;
    margin-bottom: 12px;
    @include media-breakpoint-down(md){
      margin-bottom: 8px;
    }
    .change{
      font-size: 16px;
      color: $green;
      padding-left: 24px;
    }
    label {
      font-size: 18px;
      color: $dark;
      position: relative;
      padding-left: 25px;
      margin-bottom: 4px;
      @include media-breakpoint-down(md) {
        font-size: 16px;
      }
      span{
        font-size: 14px;
        font-weight: 500;
        color: $s-main-clr;
      }
    }
    input{
      display: none;
      font-size: 20px;
    }
    input[type="radio"],input[type="checkbox"]{
      position: relative;
      top: 2px;
      margin-right: 10px;
    }
    input[type="radio"] + label::before {
      position: absolute;
      left: 0;
      top: 5px;
      width: 18px;
      height: 18px;
      display: block;
      border: 2px solid #00000054;
      content: "";
      -webkit-transition: all 0.3s ease 0s;
      -o-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s;
      border-radius: 50%;
      @include media-breakpoint-down(md){
        width: 16px;
        height: 16px;
      }
    }
    input[type="radio"] + label::after {
      position: absolute;
      left: 5px;
      top: 10px;
      display: block;
      content: "";
      opacity: 0;
      background-color: $main-clr;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      text-align: center;
      -webkit-transition: all 0.3s ease 0s;
      -o-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s;
      @include media-breakpoint-down(md){
        width: 6px;
        height: 6px;
      }
    }
    input[type="radio"]:checked + label::before {
      border: 2px solid $main-clr;
    }
    input[type="radio"]:checked + label::after {
      opacity: 1;
    }
  }
}
.payment-ul {
  padding: 32px;
  margin: 32px 0 0 0;
  border-radius: 4px;
  background-color: $white;
  box-shadow: $my-shadow;
  @include media-breakpoint-down(lg){
    padding: 32px 20px;
  }
  h5 {
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: $dark;
    margin-bottom: 24px;
    @include media-breakpoint-down(md) {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }
  .my-li{
    list-style-type: none;
    ul{
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: space-between;
      li {
        display: inline-block !important;
        .my-ul{
          padding: 0;
          margin: 0;
          li{
            display: inline-block !important;
          }
        }
        img{
          height: 20px;
          margin-right: 6px;
          @include media-breakpoint-down(sm){
            height: 13px;
            margin-right: 3px;
          }
        }
        label {
          font-size: 22px;
          position: relative;
          padding-left: 30px;
          margin-bottom: 1px;
          font-weight: 600;
          @include media-breakpoint-down(md) {
            font-size: 20px;
          }
          @include media-breakpoint-down(sm) {
            font-size: 18px;
          }
        }
        input{
          display: none;
          font-size: 20px;
        }
        input[type="radio"],input[type="checkbox"]{
          position: relative;
          top: 2px;
          margin-right: 10px;
        }
        input[type="radio"] + label::before {
          position: absolute;
          left: 0;
          top: 7px;
          width: 20px;
          height: 20px;
          display: block;
          border: 2px solid $main-clr;
          content: "";
          -webkit-transition: all 0.3s ease 0s;
          -o-transition: all 0.3s ease 0s;
          transition: all 0.3s ease 0s;
          border-radius: 50%;
          @include media-breakpoint-down(md){
            width: 18px;
            height: 18px;
          }
          @include media-breakpoint-down(sm){
            top: 4px;
          }
        }
        input[type="radio"] + label::after {
          position: absolute;
          left: 5px;
          top: 12px;
          display: block;
          content: "";
          opacity: 0;
          background-color: $main-clr;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          text-align: center;
          -webkit-transition: all 0.3s ease 0s;
          -o-transition: all 0.3s ease 0s;
          transition: all 0.3s ease 0s;
          @include media-breakpoint-down(md){
            width: 8px;
            height: 8px;
          }
          @include media-breakpoint-down(sm){
            top: 9px;
          }
        }
        input[type="radio"]:checked + label::before {
          border: 2px solid $main-clr;
        }
        input[type="radio"]:checked + label::after {
          opacity: 1;
        }
        label {
          font-size: 18px;
          color: $dark;
          position: relative;
          padding-left: 25px;
          margin-bottom: 4px;
          @include media-breakpoint-down(md) {
            font-size: 16px;
          }
          span{
            font-size: 14px;
            font-weight: 500;
            color: $s-main-clr;
          }
        }
        input{
          display: none;
          font-size: 20px;
        }
        input[type="radio"],input[type="checkbox"]{
          position: relative;
          top: 2px;
          margin-right: 10px;
        }
        input[type="radio"] + label::before {
          position: absolute;
          left: 0;
          top: 5px;
          width: 18px;
          height: 18px;
          display: block;
          border: 2px solid #00000054;
          content: "";
          -webkit-transition: all 0.3s ease 0s;
          -o-transition: all 0.3s ease 0s;
          transition: all 0.3s ease 0s;
          border-radius: 50%;
          @include media-breakpoint-down(md){
            width: 16px;
            height: 16px;
          }
        }
        input[type="radio"] + label::after {
          position: absolute;
          left: 5px;
          top: 10px;
          display: block;
          content: "";
          opacity: 0;
          background-color: $main-clr;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          text-align: center;
          -webkit-transition: all 0.3s ease 0s;
          -o-transition: all 0.3s ease 0s;
          transition: all 0.3s ease 0s;
          @include media-breakpoint-down(md){
            width: 6px;
            height: 6px;
          }
        }
        input[type="radio"]:checked + label::before {
          border: 2px solid $main-clr;
        }
        input[type="radio"]:checked + label::after {
          opacity: 1;
        }
      }
    }
  }
  p{
    font-size: 13px;
    margin: 0 0 15px 30px;
    @include media-breakpoint-down(sm){
      margin-bottom: 10px;
    }
  }
  .coupon-ul{
    display: flex;
    align-items: center;
    width: 100%;
    li{
      width: auto;
      height: 100%;
      margin-bottom: 20px;
      .form-control{
        height: 48px;
        margin-bottom: 0;
        border: none;
        box-shadow: none;
        outline: none;
        @include media-breakpoint-down(md){
          height: 45px;
        }
      }
      .read-more{
        border: none;
        box-shadow: none;
        outline: none;
      }
    }
  }
}
//.....checkout-section end.....//

//.....Product Details page.....//
.pro-details {
  .thumb-carousel {
    .carousel {
      display: flex;
      flex-direction: column-reverse;
      @include media-breakpoint-down(sm){
        flex-direction: column;
        padding-left: 0;
      }
      .carousel-indicators {
        position: relative;
        top: 0;
        left: 0;
        height: 100%;
        margin: 24px 0 0 0;
        display: flex;
        justify-content: center;
        z-index: 8;
        @include media-breakpoint-down(sm){
          justify-content: center;
          order: 1;
          width: 100%;
          padding: 0;
          flex-direction: row;
          overflow: auto !important;
          touch-action: pan-x !important;
          margin-top: 16px;
        }
        li {
          border: 1px solid transparent;
          border-radius: 0;
          display: block;
          height: auto;
          text-indent: 0;
          width: 80px;
          flex-shrink: 0;
          margin: 0 4px;
          margin-bottom: 10px;
          padding: 0;
          @include transition;
          @include media-breakpoint-down(sm){
            margin: 0 2px;
            width: 50px;
          }
          img {
            border: $border-theme;
            padding: 6px;
            @include media-breakpoint-down(sm){
              height: 50px;
              padding: 2px;
            }
          }
          &.active {
            img {
              border-color: $main-clr;
            }
          }
        }
      }
      .carousel-inner {
        border-radius: 0;
        .carousel-item {
          img {
            border-radius: 0;
            @include media-breakpoint-down(md){
              height: 280px;
            }
          }
        }
      }
    }
  }
  .product-details {
    @include media-breakpoint-down(md){
      margin-top: 30px;
    }
    h1 {
      font-size: 34px;
      font-weight: 500;
      letter-spacing: 3px;
      text-transform: uppercase;
      margin-bottom: 8px;
      color: $dark;
      @include media-breakpoint-down(lg){
        font-size: 28px;
      }
      @include media-breakpoint-down(sm){
        font-size: 24px;
      }
    }
    .ul-ratings {
      margin: 0 0 15px 0;
      padding: 0;
      li {
        display: inline-block;
        a {
          font-size: 14.5px;
          letter-spacing: 1px;
          font-weight: 600;
          line-height: 22px;
          color: $main-clr;
          margin-bottom: 50px;
          @include media-breakpoint-down(sm){
            font-size: 13px;
          }
        }
      }
      li.star {
        font-size: 12.5px;
        letter-spacing: 1px;
        padding: 3px 6px 3px 8px;
        margin-right: 5px;
        border-radius:0;
        color: $white;
        background-color: $main-clr;
        i {
          font-size: 9px;
        }
      }
    }
    .stock {
      margin: 25px 0 5px 0;
      @include media-breakpoint-down(md) {
        margin: 10px 0;
      }
      ul {
        padding: 0;
        li {
          display: inline-block;
          a {
            font-size: 18px;
            color: $main-clr;
            font-weight: 600;
          }
          h5 {
            font-size: 18px;
            font-weight: 600;
            margin: 0;
          }
        }
      }
    }
    .my-span {
      font-size: 17px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: $dark;
    }
    .select-option {
      margin: 25px 0 0 0;
      ul {
        display: flex;
        margin: 15px -4px 0 -4px;
        li {
          padding: 0 4px;
          a {
            display: block;
            min-width: 60px;
            padding: 8px 16px;
            color: $dark;
            text-align: center;
            border: $border-theme;
            border-radius: 0;
          }
          &.active {
            a {
              background-color: $light-clr;
            }
          }
        }
      }
    }
    .price {
      margin: 25px 0 0 0;
      ul{
        padding: 0;
        margin: 15px 0 0 0;
        @include media-breakpoint-down(sm){
          margin: 10px 0 0 0;
        }
        li {
          display: inline-block;
          margin-right: 10px;
          @include media-breakpoint-down(sm){
            margin-right: 7px;
          }
          h5 {
            font-size: 26px;
            color: $main-clr;
            font-weight: 600;
            @include media-breakpoint-down(sm){
              font-size: 24px;
            }
          }
          del {
            font-size: 17px;
            font-weight: 600;
            color: #cbbeb3;
          }
          h6 {
            font-size: 17px;
            font-weight: 600;
            color: $main-clr;
            margin-bottom: 0;
          }
        }
      }
    }
    .form-elements {
      margin: 16px -4px 0;
      li {
        padding: 0 4px;
        .form-group {
          max-width: 180px;
          margin: 0;
          .form-control{
            height: 44px;
            border-radius: 0;
            border: $border-theme !important;
            outline: none !important;
            box-shadow: none !important;
            background-color: $light-clr;
            @include media-breakpoint-down(lg){
              height: 38px;
            }
          }
        }
      }
    }
    .checkout-btn{
      padding: 0;
      margin: 25px 0 0 0;
      @include media-breakpoint-down(sm){
        margin: 15px 0 0 0;
      }
      li{
        display: inline-block;
        margin-right: 10px;
        @include media-breakpoint-down(sm){
          margin: 10px 5px 0 0;
        }
      }
    }
  }
}
.description {
  .tab-area {
    .nav-tabs {
      padding-bottom: 10px;
      .nav-item {
        &:not(:last-child) {
          padding: 0 8px 8px 0;
        }
        .nav-link {
          align-items: center;
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 2px;
          text-transform: uppercase;
          display: flex;
          padding: 12px 24px;
          border: $border-theme;
          border-radius: 0;
          color: $dark;
          background-color: $light-clr;
          @include transition;
          @include media-breakpoint-down(md){
            font-size: 11.5px;
            padding: 10px 18px;
          }
          &.active {
            color: $white;
            background-color: $main-clr;
            border: $border-main;
          }
        }
      }
    }
    .tab-pane {
      padding: 32px 0 0;
    }
  }
}
.review-list {
  max-height: 480px;
  overflow-y: auto;
  padding: 0 16px 0 0;
  > ul {
    > li {
      padding: 16px 0;
      border-bottom: $border-light;
      margin-bottom: -1px;
      .reviews-img{
        padding: 0;
        margin: 0 0 15px 0;
        li{
          display: inline-block;
          img{
            width: 75px;
            padding: 4px;
            margin-top: -48px;
            border-radius: 50px;
            background-color: #9cca4e66;
          }
          .reviews-name{
            padding: 0;
            margin: 0 0 0 15px;
            li{
              display: block;
              list-style-type: none;
              h4 {
                font-size: 17px;
                font-weight: 600;
                color: $dark;
                @include media-breakpoint-down(md){
                  font-size: 15px;
                }
              }
              h5 {
                font-size: 13px;
                font-weight: 600;
                margin-bottom: 2px;
              }
            }
          }
        }
      }
    }
  }
}
//.....Product Details page end.....//

//.....blog Details.....//
.blog-details-area{
  .blog-text{
    padding-top: 20px;
  }
  .blog-heading{
    font-weight: 600;
    color: $dark;
    margin-bottom: 12px;
    @include media-breakpoint-down(lg){
      font-size: 28px;
    }
    @include media-breakpoint-down(md){
      font-size: 24px;
    }
    @include media-breakpoint-down(sm){
      font-size: 22px;
    }
  }
  .my-form{
    padding: 35px 20px;
    @include media-breakpoint-down(lg) {
      padding: 25px 15px;
    }
    @include media-breakpoint-down(md) {
      padding: 25px;
    }
    @include media-breakpoint-down(sm) {
      padding: 25px 15px;
    }
  }
  .right-area {
    padding-left: 15px;
    @include media-breakpoint-down(lg){
      padding-left: 10px;
    }
    @include media-breakpoint-down(md){
      padding-top: 30px;
      padding-left: 0;
    }
    .newsletter-form{
      text-align: left;
      border: none;
      h5{
        font-size: 22px;
      }
    }
    ul {
      margin: 0 0 40px 0;
      padding: 0;
      .post-heading-li{
        margin: 0;
        padding: 0;
        border: none;
        .post-heading {
          font-size: 24px;
          font-weight: 600;
          text-transform: uppercase;
          letter-spacing: 1px;
          margin-bottom: 30px;
          color: $dark;
        }
      }
      li {
        padding: 0 0 12px 0;
        list-style: none;
        margin-bottom: 12px;
        border-bottom: $border-light;
        .recent-post {
          display: flex;
          &:hover{
            .post-details{
              .title{
                color: $main-clr;
                @include transition;
              }
            }
          }
          .post-img {
            flex-shrink: 0;
            margin-right: 20px;
            @include media-breakpoint-down(lg) {
              margin-right: 10px;
            }
            img {
              width: 110px;
              height: 100%;
              object-fit: cover;
              object-position: top;
              border-radius: 4px;
              @include media-breakpoint-down(lg) {
                width: 90px;
              }
            }
          }
          .post-details {
            .title {
              font-size: 16px;
              font-weight: 600;
              color: $dark;
              display: flex;
              margin-bottom: 1px;
              text-decoration: none;
              @include transition;
              @include media-breakpoint-down(lg) {
                font-size: 16px;
              }
            }
            p {
              margin: 4px 0;
              @include media-breakpoint-down(sm) {
                font-size: 13px;
              }
            }
            .date {
              font-size: 12px;
              font-weight: 600;
              color: #8b949a;
              letter-spacing: 0.7px;
              display: block;
              margin: 0;
            }
          }
        }
        .tags-ul{
          padding: 0;
          margin: 0;
          li{
            display: inline-block;
            margin: 0 4px 12px 0;
            border: none;
            .tags-a{
              font-size: 16px;
              font-weight: 500;
              color: $dark;
              padding: 8px 16px;
              border: $border-light;
              background-color: $white;
              @include transition;
              @include media-breakpoint-down(sm){
                font-size: 14px;
                padding: 8px 12px;
              }
              &:hover{
                color: $white;
                background-color: $main-clr;
                border-color: transparent;
              }
            }
          }
        }
      }
    }
    .login-area{
      @include media-breakpoint-down(md){
        width: 50%;
      }
      @include media-breakpoint-down(sm){
        width: 100%;
      }
    }
  }
  .comments-area{
    padding-top: 40px;
    .my-heading {
      h2 {
        font-size: 36px;
        @include media-breakpoint-down(md) {
          font-size: 32px;
        }
        @include media-breakpoint-down(sm) {
          font-size: 24px;
        }
      }
    }
  }
}
//.....blg Details end.....//

//.....Landing Page.....//
.landing-page{
  text-align: center;
  @include media-breakpoint-down(md){
    padding-top: 8px;
  }
  .logo-area{
    padding: 56px 32px;
    box-shadow: $my-shadow;
    @include media-breakpoint-down(lg){
      padding: 48px 28px;
    }
    @include media-breakpoint-down(md){
      padding: 40px 24px;
    }
    @include media-breakpoint-down(sm){
      padding: 32px 16px;
    }
    .my-logo{
      width: 300px;
      margin-bottom: 48px;
      @include media-breakpoint-down(lg){
        width: 240px;
      }
      @include media-breakpoint-down(md){
        width: 220px;
        margin-bottom: 40px;
      }
      @include media-breakpoint-down(sm){
        width: 190px;
        margin-bottom: 32px;
      }
    }
    .heading-title {
      text-align: center;
      margin-bottom: 24px;
      @include media-breakpoint-down(md){
        margin-bottom: 20px;
      }
      @include media-breakpoint-down(sm){
        margin-bottom: 16px;
      }
      h2 {
        font-size: 40px;
        font-weight: 600;
        text-transform: capitalize;
        color: $dark;
        margin: 0;
        @include media-breakpoint-down(lg){
          font-size: 36px;
        }
        @include media-breakpoint-down(md){
          font-size: 32px;
        }
        @include media-breakpoint-down(sm){
          font-size: 24px;
        }
      }
    }
    p {
      font-size: 16px;
      color: $p-clr;
      line-height: 24px;
      margin-bottom: 24px;
      @include media-breakpoint-down(md) {
        font-size: 15px;
        line-height: 22px;
        margin-bottom: 20px;
      }
      @include media-breakpoint-down(sm) {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 16px;
      }
    }
  }
}
//.....Landing Page end.....//

//popup section//
.modal-backdrop.show {
  opacity: 1;
  background-color: transparent !important;
}
.popup-modal.modal{
  z-index: 999999;
  background-color: transparent !important;
  .modal-dialog{
    max-width: 500px;
    margin-top: 110px;
    @include media-breakpoint-down(lg){
      margin-top: 100px;
    }
    @include media-breakpoint-down(md){
      max-width: 400px;
      margin-top: 94px;
    }
    @include media-breakpoint-down(sm){
      margin-top: 120px;
    }
    .modal-content{
      border: none !important;
    }
  }
  .modal-body{
    padding: 0 !important;
  }
  .close{
    opacity: 1 !important;
  }
}
//order section//
.order-section{
  position: absolute;
  top: 4%;
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  @include media-breakpoint-down(sm){
    top: 20%;
  }
  .order-area{
    width: 500px;
    padding: 20px;
    background-color: rgba(156, 202, 78, 0.68);
    @include media-breakpoint-down(lg){
      width: 440px;
      left: 26%;
      padding: 16px;
    }
    @include media-breakpoint-down(md){
      width: 340px;
      padding: 12px;
    }
    @include media-breakpoint-down(sm){
      width: 80%;
      padding: 8px;
    }
    .my-form{
      border: none;
      border-radius: 0;
      padding: 24px 72px 32px 72px;
      background-color: rgba(183, 214, 136, 0.56) !important;
      margin: 0 !important;
      @include media-breakpoint-down(lg){
        padding: 24px 40px 32px 40px;
      }
      @include media-breakpoint-down(md){
        padding: 20px 20px 24px 20px;
      }
      @include media-breakpoint-down(sm){
        padding: 20px 12px;
      }
      h4{
        font-weight: 600;
        color: $black;
        text-transform: uppercase;
        letter-spacing: 2px;
        margin-bottom: 12px;
        @include media-breakpoint-down(md){
          font-size: 24px;
        }
        @include media-breakpoint-down(sm){
          font-size: 18px;
          margin-bottom: 12px;
        }
      }
      .form-control{
        font-size: 14px;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: $black;
        height: 48px;
        border: none;
        border-radius: 0;
        margin-bottom: 12px;
        background-color: $white !important;
        @include media-breakpoint-down(md){
          height: 38px;
        }
        @include media-breakpoint-down(sm){
          height: 32px;
        }
      }
      .my-btn{
        display: flex;
        justify-content: space-between;
        li{
          width: 48%;
          .read-more{
            font-size: 14px;
            font-weight: 500;
            text-transform: uppercase;
            letter-spacing: 1px;
            padding: 12px 32px;
            width: 100%;
            background-color: $black;
            @include media-breakpoint-down(md) {
              padding: 12px 20px;
            }
            @include media-breakpoint-down(sm){
              font-size: 11px;
              padding: 8px;
            }
          }
        }
      }
    }
  }
}
.order-section-modal{
  .order-area{
    background-color: #83a54a;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
    .my-form{
      background-color: #b7d688 !important;
    }
  }
}
//order section end//

//coupon page//
.coupon-page{
  .coupon-section{
    width: 100%;
    box-shadow: $my-shadow;
  }
}
//coupon page end//

// ..............................New.................................//
//order page pro//
.order-page{
  @include media-breakpoint-down(sm){
    padding-top: 24px !important;
  }
  .prod{
    width: 100%;
    overflow: hidden;
    .view{
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      border-radius: 4px;
      padding: 12px 16px;
      background-color: #f3f5f8;
      z-index: 4;
      @include media-breakpoint-down(lg){
        padding: 8px 12px;
      }
      @include media-breakpoint-down(sm){
        padding: 6px 12px;
      }
      .layout{
        font-size: 18px;
        font-weight: 500;
        color: $dark;
        @include media-breakpoint-down(lg){
          font-size: 16px;
        }
        @include media-breakpoint-down(sm){
          font-size: 14px;
        }
      }
      span{
        @include transition;
        &:hover, &.selected{
          i{
            color: $main-clr;
          }
        }
        i{
          cursor:pointer;
          font-size: 18px;
          color: $dark;
          padding: 0 0 0 16px;
          @include transition;
        }
      }
    }
    .item{
      float:left;
    }
  }
  .layout-tab-area{
    #top-menus{
      width: 74.4%;
      position: absolute;
      top: 66px;
      right: 0;
      justify-content: flex-end;
      align-items: center;
      border-radius: 4px;
      padding: 14px 16px;
      background-color: #f3f5f8;
      z-index: 4;
      @include media-breakpoint-down(lg){
        top: 68px;
      }
      @include media-breakpoint-down(md){
        width: 65.4%;
      }
      @include media-breakpoint-down(sm){
        width: 100%;
        top: 44px;
        padding: 8px;
        justify-content: center;
      }
      .my-nav-item{
        @include media-breakpoint-down(sm){
          padding: 0 !important;
        }
      }
      .nav-item{
        padding: 0 0 0 10px;
        @include media-breakpoint-down(md){
          padding: 0 0 0 8px;
        }
        .nav-link{
          font-size: 18px;
          font-weight: 500;
          color: $dark;
          margin: 0;
          padding: 0;
          border-radius: 0;
          background-color: transparent;
          box-shadow: none;
          @include transition;
          @include media-breakpoint-down(lg){
            font-size: 16px;
          }
          @include media-breakpoint-down(sm){
            font-size: 15px;
          }
          &.active, &:hover{
            color: $green;
          }
          i{
            margin-right: 6px;
          }
        }
      }
    }
  }
  .my-tab-slider{
    display: none;
    @include media-breakpoint-down(sm){
      display: block;
    }
  }
  .tab-slider {
    padding: 0 20px 32px 20px;
    position: relative;
    margin: 0 !important;
    @include media-breakpoint-down(md){
      padding: 0 20px 24px 20px;
    }
    @include media-breakpoint-down(sm){
      padding: 0 20px 12px 20px;
    }
    .wrap {
      position: relative;
      white-space: nowrap;
      width: 100%;
      background: transparent;
      border: none;
      font-size: 0;
      overflow: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .btn-icon {
      position: absolute;
      top: 5px;
      padding: 0;
      border: none;
      outline: none;
      box-shadow: none;
      i{
        font-size: 20px;
        @include media-breakpoint-down(md){
          font-size: 16px;
        }
      }
    }
    .goPrev {
      left:0;
    }
    .goNext {
      right:0;
    }
    .nav-tabs>li.active>a,
    .nav-tabs>li.active>a:focus,
    .nav-tabs>li.active>a:hover {
      border: 1px solid transparent;
    }
    .wrap>.nav-tabs {
      display: inline-block;
      padding: 0;
      margin: 0;
      position: relative;
      top: 0;
      left: 0;
    }
    .wrap>.nav-tabs>li {
      background: #fff;
      display: inline-block;
      position: relative;
      white-space: normal;
      float: none;
      font-size: 14px;
    }
    .nav-tabs>li>a {
      margin-right: 0;
      border-radius: 0;
    }
  }
  .order-category{
    padding: 32px 20px;
    margin-right: 12px;
    border-radius: 4px;
    border: 1px solid #ebebeb;
    @include media-breakpoint-down(lg){
      padding: 32px 15px;
      margin-right: 8px;
    }
    @include media-breakpoint-down(sm){
      display: none;
    }
    .category-h5{
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: $main-clr;
      margin-bottom: 24px;
      @include media-breakpoint-down(lg){
        font-size: 20px;
      }
    }
    #my-menus{
      width: 100%;
      .nav-item{
        width: 100%;
        padding: 0;
        .nav-link{
          font-size: 16px;
          margin: 0;
          padding: 12px 14px;
          border-radius: 0;
          border-bottom: $border-light !important;
          background-color: transparent;
          box-shadow: none;
          @include transition;
          @include media-breakpoint-down(lg){
            font-size: 15px;
            padding: 12px;
          }
          &.active, &:hover{
            color: $dark;
            border-radius: 0;
            border-bottom: 1px solid transparent !important;
            background-color: $main-clr;
          }
          img{
            max-width: 24px;
            margin-right: 8px;
            @include media-breakpoint-down(lg){
              max-width: 20px;
              margin-right: 6px;
            }
          }
        }
      }
    }
  }
  .no-gutters{
    .pro-col{
      margin: 0 4px !important;
      @include media-breakpoint-down(lg){
        margin: 0 3px !important;
      }
      @include media-breakpoint-down(sm){
        margin: 0 !important;
      }
    }
    .grid-view-i{
      @include media-breakpoint-down(md){
        .fa-th{
          display: none !important;
        }
        .fa-grip-vertical{
          display: block !important;
        }
      }
    }
    .grid2-view-i{
      @include media-breakpoint-down(md){
        display: none !important;
      }
    }
    .prod.grid-view{
      .pro-col{
        @include media-breakpoint-down(md){
          width: 48% !important;
          margin: 0 4px !important;
        }
        @include media-breakpoint-down(sm){
          width: 50% !important;
          margin: 0 !important;
        }
      }
    }
    .prod.grid2-view{
      .pro-col{
        @include media-breakpoint-down(md){
          width: 50% !important;
          display: none !important;
        }
      }
    }
    .prod.list-view{
      .pro-col{
        @include media-breakpoint-down(md){
          width: 100% !important;
          margin: 0 !important;
        }
      }
    }
  }
  .pro-area-section{
    display: flex;
    flex-flow: wrap;
    width: 100%;
    .pro-col{
      width: 32.33%;
      margin: 0 6px;
      @include media-breakpoint-down(lg){
        margin: 0 4px;
      }
      @include media-breakpoint-down(md){
        margin: 0 3px;
      }
      @include media-breakpoint-down(sm){
        margin: 0;
      }
    }
  }
  .grid-view-i{
    .fa-grip-vertical{
      display: none !important;
    }
    @include media-breakpoint-down(sm){
      .fa-th{
        display: none !important;
      }
      .fa-grip-vertical{
        display: block !important;
      }
    }
  }
  .grid2-view-i{
    @include media-breakpoint-down(sm){
      display: none !important;
    }
  }
  .prod.grid-view{
    .pro-col{
      width: 32.33%;
      @include media-breakpoint-down(sm){
        width: 50% !important;
      }
    }
  }
  .prod.grid2-view{
    .pro-col{
      width: 24%;
      @include media-breakpoint-down(sm){
        width: 50% !important;
        display: none !important;
      }
    }
  }
  .prod.list-view{
    .pro-col{
      width: 49%;
      @include media-breakpoint-down(sm){
        width: 100% !important;
      }
    }
  }
}
#footer{
  z-index: 8 !important;
}
//order page pro end//
// ..............................New end.................................//

