.read-more {
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: $white !important;
  padding: 12px 32px;
  text-decoration: none;
  outline: none;
  border: none;
  border-radius: 0;
  background-color: $main-clr;
  box-shadow: none;
  @include transition;
  @include media-breakpoint-down(md) {
    font-size: 14px;
    padding: 12px 20px;
  }
  &.active, &:focus, &:hover {
    background-color: $s-main-clr;
  }
}