//nav//
body{
  @include media-breakpoint-down(md){
    margin-top: 76px;
  }
  @include media-breakpoint-down(sm){
    margin-top: 62px;
  }
}
header {
  width: 100%;
  z-index: 10;
  @include media-breakpoint-down(md){
    position: absolute;
    top: 0;
    left: 0;
  }
  .nav_area {
    width: 100%;
    background-color: $s-main-clr;
    padding: 16px 0;
    @include media-breakpoint-down(sm){
      padding: 12px 0;
    }
    #logo {
      @include media-breakpoint-down(md){
        flex: 1;
      }
      a{
        .my-logo {
          width: 180px;
          @include media-breakpoint-down(lg) {
            width: 150px;
          }
          @include media-breakpoint-down(sm) {
            width: 130px;
          }
        }
      }
    }
    #nav-manu{
      @include media-breakpoint-down(sm){
        padding: 0 15px;
      }
      nav.navbar {
        width: 100%;
        padding: 0;
        justify-content: left;
        .navbar-toggler {
          padding: 0 0 0 4px;
          border: none;
          .navbar-toggler-icon {
            width: 24px;
            height: 18px;
            background-image: none;
            position: relative;
            border-bottom: 2px solid $white;
            @include transition;
            @include media-breakpoint-down(sm) {
              width: 21px;
            }
            &::after, &::before {
              width: 24px;
              position: absolute;
              height: 2px;
              background-color: $white;
              top: 0;
              left: 0;
              content: '';
              z-index: 2;
              @include transition;
              @include media-breakpoint-down(sm) {
                width: 21px;
              }
            }
            &::after {
              top: 8px;
            }
          }
          &[aria-expanded="true"] {
            .navbar-toggler-icon {
              border-color: transparent;
              &::after {
                transform: rotate(45deg);
              }
              &::before {
                transform: translateY(8px) rotate(-45deg);
              }
            }
          }
        }
        button:focus {
          outline: none;
        }
        .collapse {
          justify-content: center;
          @include media-breakpoint-down(md) {
            order: 2;
          }
        }
        .navbar-nav {
          .nav-link {
            @include media-breakpoint-down(md) {
              margin-top: 14px;
            }
          }
        }
        .nav-link {
          font-size: 14px;
          font-weight: 600;
          text-transform: uppercase;
          letter-spacing: 1px;
          color: $white;
          padding: 0;
          margin-left: 20px;
          border-bottom: 2px solid transparent;
          @include transition;
          @include media-breakpoint-down(lg){
            margin-left: 14px;
          }
          @include media-breakpoint-down(md) {
            padding: 0;
            margin-left: 0;
          }
          &.active, &:active, &:focus, &:hover {
            border-bottom: 2px solid $white;
            transition: all 0.3s;
            @include media-breakpoint-down(md){
              border: none;
              padding: 8px;
              background-color: #97c152;
            }
          }
        }
      }
      .icon-area {
        margin: 0;
        @include media-breakpoint-down(md){
          order: 1;
          position: absolute;
          top: 9px;
          right: 6%;
        }
        @include media-breakpoint-down(sm){
          top: 8px;
          right: 10%;
        }
        a.nav-link {
          border: none !important;
          @include media-breakpoint-down(md) {
            margin-left: 20px;
          }
          @include media-breakpoint-down(sm) {
            margin-left: 16px;
          }
          .nav-icon{
            width: 37px;
            @include media-breakpoint-down(lg){
              width: 32px;
            }
            @include media-breakpoint-down(sm){
              width: 24px;
            }
          }
        }
        .download-btn{
          font-weight: 600 !important;
          color: $dark !important;
          background-color: $warning;
          padding: 8px 16px !important;
          margin: 0;
          border-radius: 0;
          border: none !important;
          box-shadow: none;
          outline: none;
          @include transition;
          @include media-breakpoint-down(lg){
            padding: 6px 12px !important;
          }
          @include media-breakpoint-down(md){
            margin: 0 !important;
          }
          @include media-breakpoint-down(sm){
            font-size: 10.2px !important;
            line-height: normal;
            padding: 8px 4px !important;
          }
          &:hover{
            background-color: $main-clr;
          }
        }
        .nav-icon-area{
          @include media-breakpoint-down(sm){
            display: none;
          }
        }
      }
    }
  }
}
//nav end//