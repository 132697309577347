//primary gradient
@mixin primary-linear-gradient() {
  background: $main-clr;
  background: linear-gradient(135deg, $s-main-clr 45%, $main-clr 100%);
}
// mixin
@mixin transition($property:all){
  transition: $property .7s ease;
}
@mixin translateXmiddle{
  left: 50%;
  transform: translateX(-50%);
}
@mixin translateYmiddle{
  top: 50%;
  transform: translateY(-50%);
}
@mixin translatemiddle{
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}