.pro-card-3{
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 12px;
  @include media-breakpoint-down(lg){
    padding: 8px;
  }
  @include media-breakpoint-down(sm){
    padding: 6px 4px;
    margin-top: 8px;
    border-bottom: 1px solid #00000014 !important;
  }
  .pro-img{
    padding: 4px;
    margin: 0;
    width: 220px;
    @include media-breakpoint-down(lg){
      width: 180px;
    }
    @include media-breakpoint-down(sm){
      width: 120px;
      margin-bottom: 12px;
    }
    img{
      border-radius: 4px;
      width: 100%;
    }
  }
  .pro-text {
    width: 100%;
    text-align: left;
    padding: 0 0 0 12px;
    @include media-breakpoint-down(lg){
      padding: 0 0 0 8px;
    }
    @include media-breakpoint-down(sm){
      padding: 0;
    }
    h5{
      font-size: 16px !important;
      @include media-breakpoint-down(sm){
        font-size: 15px !important;
      }
    }
  }
  .pro-ul{
    padding: 0;
    justify-content: space-between;
    align-items: center;
    li{
      margin: 0 4px;
      @include transition;
      h6{
        margin: 8px 0;
        @include media-breakpoint-down(sm){
          font-size: 15px;
        }
      }
      a{
        padding: 4px 8px;
        @include media-breakpoint-down(sm){
          padding: 2px 8px;
        }
      }
      .customise-btn{
        @include transition;
      }
      .basket-btn{
        @include transition;
      }
    }
  }
}