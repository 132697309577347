.my-heading {
  text-align: center;
  margin-bottom: 40px;
  position: relative;
  z-index: 3;
  @include media-breakpoint-down(md){
    margin-bottom: 32px;
  }
  h2 {
    color: $dark;
    font-weight: 600;
    text-transform: capitalize;
    position: relative;
    display: inline-block;
    text-align: center;
    margin: 0;
    @include media-breakpoint-down(md){
      font-size: 36px;
    }
    @include media-breakpoint-down(sm){
      font-size: 28px;
    }
    span {
      display: inline-block;
      padding: 0 8px;
      background-color: $white;
    }
    &:after {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 150%;
      position: absolute;
      top: 50%;
      margin: 0;
      border-bottom: 1px solid;
      border-color: $main-clr;
      left: -25%;
      transform: translateY(-50%);
      z-index: -1;
    }
  }
}